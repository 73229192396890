import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Box = makeShortcode("Box");
const Chocolate = makeShortcode("Chocolate");
const Icon = makeShortcode("Icon");
const Checklist = makeShortcode("Checklist");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <StatusBanner warning mdxType="StatusBanner">
      <p><strong parentName="p">{`This section is under construction. Join the `}<a parentName="strong" {...{
            "href": "https://chat.makerdao.com/channel/translation"
          }}>{`#translation`}</a>{` channel to learn more about translation opportunities.`}</strong></p>
    </StatusBanner>
    <h1>{`Translations Initiative`}</h1>
    <p>{`Welcome to the MakerDAO Translations Initiative, a place where community members can help expand the reach of MakerDAO educational resources by translating them into various languages.`}</p>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`Project Snapshot`}</h2>
      <Chocolate mdxType="Chocolate">
        <Box mdxType="Box">
          <Icon size={"40px"} name="people" mdxType="Icon" />
          <p><strong parentName="p">{`Be Part of a Team`}</strong></p>
        </Box>
        <Box mdxType="Box">
          <Icon size={"40px"} name="dai_circle_color" mdxType="Icon" />
          <p><strong parentName="p">{`Earn Bounties in Dai`}</strong></p>
        </Box>
        <Box mdxType="Box">
          <Icon size={"40px"} name="wrench" mdxType="Icon" />
          <p><strong parentName="p">{`Make MakerDAO & Dai More Accessible`}</strong></p>
        </Box>
      </Chocolate>
    </Box>
    <h2>{`What Are We Trying to Accomplish?`}</h2>
    <p>{`MakerDAO is an inherently global platform, which facilitates the creation of Dai. As MakerDAO and Dai spread across the world, our focus is on creating universally accessible resources. `}</p>
    <p>{`With inclusivity as a foundational part of our culture, our goal is to translate our current resources into as many languages as possible. Through the Translations Initiative, we are working to grant wider audiences access to materials about MakerDAO in their native languages.`}</p>
    <h2>{`Project Description`}</h2>
    <p>{`This is a community-led initiative that uses Dai bounties to incentivize users to translate MakerDAO resources into their native languages.`}</p>
    <h2>{`Is This for Me?`}</h2>
    <Checklist mdxType="Checklist">
      <p>{`Fluent`}</p>
      <p>{`Bilingual`}</p>
      <p>{`Primary languages: Spanish, Korean, Polish, Japanese, Mandarin, Italian, and French`}</p>
      <p>{`Basic knowledge of MakerDAO`}</p>
      <p>{`Basic familiarity with markdown`}</p>
    </Checklist>
    <h2>{`How Do I Get Involved?`}</h2>
    <p>{`Check out our quick-start guides for `}<a parentName="p" {...{
        "href": "/contribute/translations/translators/"
      }}>{`Translators`}</a>{` and `}<a parentName="p" {...{
        "href": "/contribute/translations/editors/"
      }}>{`Editors`}</a>{`.`}</p>
    <h2>{`Status`}</h2>
    <p><a parentName="p" {...{
        "href": "https://airtable.com/shr415iT3e8S8nuzS"
      }}>{`Applications`}</a>{` are open for both translators and editors.`}</p>
    <p>{`As of June 2019, thirty-five documents have been translated across seven different languages. `}</p>
    <p>{`We are currently working with Spanish, Korean, Polish, Japanese, Mandarin, Italian, and French and open to incorporating other languages for interested applicants. `}</p>
    <p>{`Please include the proposed language in the `}<a parentName="p" {...{
        "href": "https://airtable.com/shr415iT3e8S8nuzS"
      }}>{`application`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      